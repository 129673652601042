var $ = require('jquery');

var preloader = require('preloader');
var isMobile = require('ismobilejs');

var gsap = require('gsap');
var TweenMax = gsap.TweenMax;
var Easing = gsap.Easing;

function Eatmeater() {

  var Eatmeater;

  var $body;
  var $container;
  var $production;
  var production;
  var $loading;
  var $isSmall;
  var st;

  var screenW;
  var screenH;


  this.load = function () {

    $body = $("body");
    $container = $("#container");
    $production = $("#production");
    $loading = $("#loading");
    $isSmall = $("#isSmall");

    Eatmeater = this;

    $(window).on('hashchange', function () {
      Eatmeater.updateNavigation();
    });

    var isDebug = this.getUrlVars()["isDebug"];
    if (isDebug == "true") {
      $body.attr('data-debug', 'true');
    } else {
      $body.attr('data-debug', 'false');
    };

    screenOrientation = require('screen-orientation');

    st = 0;

    $("#video").find(".close").click(function () {
      $("#video").find(".container").html("");
      $body.attr('data-display-video', 'false');
    });

    $("#footer").find(".center").click(function () {
      window.location.hash = "contact";
    });

    $(".nav-list").find(".production").each(function (index) {
      $(this).click(function () {
        window.location.hash = "production";
      });
    });

    $(".nav-list").find(".studio").each(function (index) {
      $(this).click(function () {
        window.location.hash = "studio";
      });
    });

    $(".nav-list").find(".record").each(function (index) {
      $(this).click(function () {
        window.location.hash = "record";
      });
    });

    this.draw();

    window.scrollTo(0, 0);

    Eatmeater.log("");
    Eatmeater.log("Load");
    Eatmeater.log("");

    var loader = preloader({
      xhrImages: true,
      loadFullAudio: false,
      loadFullVideo: false
    });

    loader.on('progress', function (progress) {
      // Eatmeater.log("Assets loading: "+progress);
    });

    loader.on('complete', function () {
      Eatmeater.log("All assets loaded");
      Eatmeater.log("");
      Eatmeater.init();
    });

    //  Adding assets to loader

    $("#loading").find("img").each(function (index) {
      var src = $(this).attr('src');
      Eatmeater.log("Image: " + src);
      loader.add(src);
    });

    Eatmeater.log("JSON: json/production.json");
    loader.add("json/production.json", {
      onComplete: function (content) {

        production = content;

        var item, type, content, node, style, img;
        var items = content["items"];

        var partition = "<div class='partition'></div>";

        Eatmeater.log("");
        Eatmeater.log("Production: ");
        Eatmeater.log(items);
        Eatmeater.log("");

        for (var i = 0; i < items.length; i++) {

          item = items[i];
          type = item["type"];
          style = item["style"]
          content = item["content"];

          if (type == "quote") {
            node = "<div class='quote'><div class='ql'></div><div class='copy-container'><div class='copy'>" + content + "</div></div><div class='qr'></div></div>";
            $production.append(node);
          };

          if (type == "case") {

            img = "";

            if (content["video"]["image"] != null) {
              img = content["video"]["image"];
            } else {
              img = "https://img.youtube.com/vi/" + content["video"]["id"] + "/maxresdefault.jpg";

            }

            node = "";
            node += "<div class='case case-" + style + " ' data-id='" + i + "'>";
            node += "<div class='image'><div class='holder'style=background-image:url('" + img + "')><img src='images/guide.jpg'></div>";

            node += "<div class='info'>";
            node += "Client: <b>" + content["client"] + "</b>";
            node += partition;
            node += "Agency: <b>" + content["agency"] + "</b>";
            node += partition;
            node += "Year: <b>" + content["year"] + "</b>";
            node += partition;
            node += "Duration: <b>" + content["duration"] + "</b>";
            node += partition;
            node += "Style: <b>" + content["style"] + "</b>";
            node += partition;
            node += "Role: <b>" + content["role"] + "</b>";
            node += "</div>";
            node += "<div class='header'><div class='hl'></div><h1>" + content["title"] + "</h1><div class='hr'></div></div>";


            node += "</div>"

            $production.append(node);

          };

        }

        $("#production").find(".case").find(".image").each(function (index) {
          $(this).click(function () {
            //
            var id = parseInt($(this).parent().attr("data-id"));
            var data = production["items"][id];
            var content = data["content"];
            var videoType = data["content"]["video"]["type"];
            var videoId = data["content"]["video"]["id"];
            //
            var nodeVideo = "";
            if (videoType == "youtube") {
              nodeVideo += "<iframe frameborder='0' allowfullscreen='1' src='https://www.youtube.com/embed/" + videoId + "?autoplay=0&amp;controls=1&amp;rel=0&amp;theme=light&amp;enablejsapi=1&amp;&amp;widgetid=1'></iframe>"
            }
            //
            if (videoType == "vimeo") {
              nodeVideo += "<iframe frameborder='0' allowfullscreen='1' webkitallowfullscreen mozallowfullscreen allowfullscreen src='https://player.vimeo.com/video/" + videoId + "'></iframe>";
            }
            //
            var nodeInfo = "";

            nodeInfo += "Client: <b>" + content["client"] + "</b>";
            nodeInfo += partition;
            nodeInfo += "Agency: <b>" + content["agency"] + "</b>";
            nodeInfo += partition;
            nodeInfo += "Year: <b>" + content["year"] + "</b>";
            nodeInfo += partition;
            nodeInfo += "Duration: <b>" + content["duration"] + "</b>";
            nodeInfo += partition;
            nodeInfo += "Style: <b>" + content["style"] + "</b>";
            nodeInfo += partition;
            nodeInfo += "Role: <b>" + content["role"] + "</b>";

            //
            if (videoType == "external") {
              window.open(videoId, '_blank');
            } else {
              $("#video").find(".container").html(nodeVideo);
              $("#video").find(".info").html(nodeInfo);
              $body.attr('data-display-video', 'true');
            }

          });
        });
        //
        setTimeout(function () {
          Eatmeater.updateNavigation();
        }, 1000);
        //
      }
    });

    Eatmeater.log("");
    Eatmeater.log("Total assets loading: " + loader.urls.length);
    Eatmeater.log("");
    loader.load();
  };

  this.updateNavigation = function () {

    Eatmeater.log("window.location.hash: " + window.location.hash);

    if (window.location.hash == null || window.location.hash == "") {
      window.location.hash = "production";
      return;
    }

    var hash = window.location.hash;
    var page = hash.slice(1, hash.length);

    $body.attr('data-state', page);
    Eatmeater.resize();
    window.scrollTo(0, 0);

  };

  this.tracking = function (action, category, label) {
    // FB.AppEvents.logEvent(action + " " + category + " " + label);
  };

  this.init = function () {

    var that = this;

    var hash = window.location.hash;

    this.tracking("open", "page", "frontpage");

    Eatmeater.log("Start");


    $(window).resize(function () {
      Eatmeater.resize();
      Eatmeater.update();
    });

    this.resize();
    this.update();

  };

  this.resize = function () {
    screenW = parseInt(Eatmeater.getWindowCoords()[0]);
    screenH = parseInt(Eatmeater.getWindowCoords()[1]);

    var footerH = $("#footer").find(".center").height();
    Eatmeater.log("footerH: " + footerH);
    $("#footer").find(".left").height(footerH);
    $("#footer").find(".right").height(footerH);

    var sizes = [2000, 1000, 640];

    $body.find("img").each(function (index) {
      var src = $(this).attr("data-src");
      if (src) {
        // $(this).attr("src", "/images/guide.jpg");
        var imgWidth = $(this).width();
        // console.log("imgWidth: " + imgWidth);
        var selectedW = sizes[0];
        for (var i = 0; i < sizes.length; i++) {
          var w = sizes[i];
          if (imgWidth * 2 < w) {
            selectedW = w;
          }
        }
        // console.log("selectedW: " + selectedW);
        src = src.replace("[size]", selectedW)
        $(this).attr("src", src);
      }
    });

  };

  this.update = function () {

    if (isMobile.phone) {
      $body.attr('data-orientation', screenOrientation().direction);
    } else {
      $body.attr('data-orientation', 'portrait');
    };

    /*
    if ($isSmall.css("display") == "none") {
    } else {
    }
    */
  };

  this.draw = function () {
    st = $body.scrollTop();
    if (st > 80) {
      $body.attr('data-nav-min', 'true');
    } else {
      $body.attr('data-nav-min', 'false');
    }
    window.requestAnimationFrame(Eatmeater.draw);
  }

  this.getWindowCoords = function () {

    if (window.innerWidth || window.innerHeight) {

      return [window.innerWidth, window.innerHeight];

    } else {

      return [(document.documentElement.clientWidth || document.body.clientWidth || document.body.scrollWidth),
        (document.documentElement.clientHeight || document.body.clientHeight || document.body.scrollHeight)
      ];
    }
  };

  this.log = function (key) {

    if (window.console && typeof window.console.log == "function") {
      if (key == "") {
        console.log("");
      } else {
        console.log("Eatmeater | ", key);
      }
    }

  };

  this.getUrlVars = function () {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      var hash = value.indexOf("#");
      if (hash >= 1) {
        value = value.slice(0, hash);
      }
      vars[key] = value;
    });

    return vars;
  }

  this.isSafari = function () {
    var ua = navigator.userAgent.toLowerCase();

    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        return false;
      } else {
        return true;
      }
    }


  };

  this.isAndroid = function () {

    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    return isAndroid;

  };

  this.createCookie = function (name, value, days) {
    var expires;
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }

    Eatmeater.log("createCookie!");

    document.cookie = name + "=" + value + expires + "; path=/";
  };

  this.getCookie = function (c_name) {

    Eatmeater.log("document.cookie.length: " + document.cookie.length);

    if (document.cookie.length > 0) {
      var c_start = document.cookie.indexOf(c_name + "=");
      if (c_start != -1) {
        var c_start = c_start + c_name.length + 1;
        var c_end = document.cookie.indexOf(";", c_start);
        if (c_end == -1) {
          c_end = document.cookie.length;
        }
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return "";
  };

}

module.exports = Eatmeater;