
$ = require('jquery');

var eatmeater = require("./js/Eatmeater.js");

$(function() {

    var Eatmeater = new eatmeater();

    setTimeout(function(){
        Eatmeater.load();
    },1000);

});
